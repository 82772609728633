import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import UserForm from "./UserForm";
import PopupForm from "@/pages/components/PopupForm";
import RegionSelect from "@/pages/components/RegionSelect";
export default {
  name: "AddForm",
  props: ["visible", "editMode"],
  components: {
    UserForm: UserForm,
    PopupForm: PopupForm,
    RegionSelect: RegionSelect
  },
  i18n: require("./i18n-addform"),
  data: function data() {
    return {
      liaisons: []
    };
  },
  beforeCreate: function beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    loadForm: function loadForm(record, data) {
      // let editForm = {
      //   deviceSn: data.deviceSn,
      //   deviceAddress: data.deviceAddress,
      //   model: data.deviceModel?.deviceModelType,
      //   address: data.address,
      // }
      if (data) {
        this.form.setFieldsValue({
          'useradd.customerName': data.customerName,
          'useradd.customerAddress': data.customerAddress,
          'useradd.produceScale': data.produceScale + '',
          'useradd.username': data.username,
          'useradd.remarks': data.remarks,
          'useradd.place': [data.province, data.city, data.town]
        });
        this.liaisons = this.$refs.refuf.loadForm(data);
      } else {
        this.form.setFieldsValue({
          'useradd.customerName': record.customerName,
          'useradd.customerAddress': record.customerAddress,
          'useradd.produceScale': record.produceScale + '',
          'useradd.username': record.username,
          'useradd.remarks': record.remarks,
          'useradd.place': [record.province, record.city, record.town]
        });
        this.$message.warn('请完善客户信息', 3);
      }
    },
    validateLiaisons: function validateLiaisons() {
      if (this.liaisons.length === 0) {
        this.$message.warn('请至少添加一个联系人', 3);
        return true;
      }

      if (this.liaisons.filter(function (item) {
        return item.editable === true;
      }).length > 0) {
        this.$message.warn('请先保存正在编辑的联系人', 3);
        return true;
      }

      return false;
    },
    appendLiaisons: function appendLiaisons(v) {
      v.useradd.liaisonList = this.liaisons.map(function (i) {
        var ti = _objectSpread({}, i);

        delete ti.isNew;
        delete ti.editable;
        delete ti.key;
        return ti;
      });
    },
    liaisonsChange: function liaisonsChange(v) {
      this.liaisons = v;
    }
  }
};