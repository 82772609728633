import _toConsumableArray from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.trim.js";
var columns = [{
  title: "联系人",
  dataIndex: "liaisonName",
  key: "liaisonName",
  width: "20%",
  scopedSlots: {
    customRender: "liaisonName"
  }
}, {
  title: "联系电话",
  dataIndex: "phone",
  key: "phone",
  width: "20%",
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  width: "40%",
  scopedSlots: {
    customRender: "remark"
  }
}, {
  title: "是否主要联系人",
  dataIndex: "master",
  key: "master",
  width: "10%",
  scopedSlots: {
    customRender: "master"
  }
}, {
  title: "操作",
  key: "operation",
  scopedSlots: {
    customRender: "operation"
  }
}]; // const dataSource = [
//   {
//     key: 1,
//     name: '小明',
//     number: '001',
//     editable: false,
//     department: '行政部'
//   }
// ]
// eslint-disable-next-line no-unused-vars

import { dataSource as ds } from "@/services";
export default {
  name: "UserForm",
  // props: ['value'],
  data: function data() {
    return {
      columns: columns,
      tempObj: null,
      dataSource: [{
        key: 0,
        liaisonName: "",
        phone: "",
        remark: "",
        master: 1,
        editable: true,
        isNew: true
      }]
    };
  },
  computed: {
    dataColumns: function dataColumns() {
      return this.columns;
    }
  },
  methods: {
    loadForm: function loadForm(data) {
      // console.log("uf loadForm", data)
      if (Array.isArray(data.liaisonList) && data.liaisonList.length > 0) {
        var _ds = data.liaisonList.map(function (d, i) {
          return {
            key: i,
            liaisonName: d.liaisonName,
            liaisonId: d.liaisonId,
            phone: d.phone,
            remark: d.remark,
            master: d.master,
            editable: false,
            isNew: false
          };
        });

        this.dataSource = _ds;
        return this.dataSource;
      }

      return [];
    },
    onChange: function onChange(value) {
      this.$emit("change", value);
    },
    handleSubmit: function handleSubmit(e) {
      e.preventDefault();
    },
    newMember: function newMember() {
      if (this.dataSource.filter(function (item) {
        return item.editable === true;
      }).length > 0) {
        this.$message.warn("请先保存正在编辑的联系人", 3);
        return;
      }

      this.dataSource.push({
        key: this.dataSource[this.dataSource.length - 1].key + 1,
        liaisonName: "",
        phone: "",
        remark: "",
        master: 0,
        editable: true,
        isNew: true
      });
    },
    onMasterChange: function onMasterChange(key) {
      // console.log("onMasterChange", key);
      this.dataSource.forEach(function (i) {
        i.master = 0;
      });
      key.master = key.master === 0 ? 1 : 0;
      this.onChange(this.dataSource);
    },
    remove: function remove(record) {
      if (this.dataSource.length === 1) {
        this.$message.warn("无法删除唯一联系人", 3);
        return;
      }

      var newData = this.dataSource.filter(function (item) {
        return item.key !== record.key;
      });

      if (record.master === 1) {
        newData[0].master = 1;
      }

      this.dataSource = newData;
      this.onChange(this.dataSource);
    },
    saveRow: function saveRow(key) {
      var target = this.dataSource.filter(function (item) {
        return item.key === key;
      })[0];

      if (target.liaisonName.trim() === "") {
        this.$message.warn("联系人名字不能为空", 3);
        return;
      }

      if (target.phone.trim() === "") {
        this.$message.warn("联系人电话不能为空", 3);
        return;
      }

      target.editable = false;
      target.isNew = false;
      this.onChange(this.dataSource);
    },
    editRow: function editRow(key) {
      if (this.dataSource.filter(function (item) {
        return item.editable === true;
      }).length > 0) {
        this.$message.warn("请先保存或取消正在编辑的项目", 3);
        return;
      }

      var target = this.dataSource.filter(function (item) {
        return item.key === key;
      })[0];
      this.tempObj = _objectSpread({}, target);
      target.editable = true; // target.editable = !target.editable
      // this.onChange(this.dataSource)
    },
    getRowByKey: function getRowByKey(key, newData) {
      var data = this.dataSource;
      return (newData || data).filter(function (item) {
        return item.key === key;
      })[0];
    },
    cancle: function cancle(key) {
      var target = this.dataSource.filter(function (item) {
        return item.key === key;
      })[0];
      target.liaisonName = this.tempObj.liaisonName;
      target.phone = this.tempObj.phone;
      target.remark = this.tempObj.remark;
      target.master = this.tempObj.master;
      target.editable = false;
    },
    handleChange: function handleChange(value, key, column) {
      var newData = _toConsumableArray(this.dataSource);

      var target = newData.filter(function (item) {
        return key === item.key;
      })[0];

      if (target) {
        target[column] = value;
        this.dataSource = newData;
      }
    }
  }
};