var render = function render() {
  var _this = this;

  var _vm = this,
      _c = _vm._self._c;

  return _c('form', {
    attrs: {
      "autoFormCreate": function autoFormCreate(form) {
        return _this.form = form;
      }
    }
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.dataColumns,
      "dataSource": _vm.dataSource,
      "pagination": false
    },
    scopedSlots: _vm._u([_vm._l(['liaisonName', 'phone', 'remark'], function (col, i) {
      return {
        key: col,
        fn: function fn(text, record) {
          return [record.editable ? _c('a-input', {
            key: col,
            staticStyle: {
              "margin": "-5px 0"
            },
            attrs: {
              "value": text,
              "placeholder": _vm.columns[i].title
            },
            on: {
              "change": function change(e) {
                return _vm.handleChange(e.target.value, record.key, col);
              }
            }
          }) : [_vm._v(_vm._s(text))]];
        }
      };
    }), {
      key: "master",
      fn: function fn(text, record) {
        return [_c('a-switch', {
          attrs: {
            "checked": text === 1
          },
          on: {
            "change": function change($event) {
              return _vm.onMasterChange(record);
            }
          }
        })];
      }
    }, {
      key: "operation",
      fn: function fn(text, record) {
        return [record.editable ? [record.isNew ? _c('span', [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.saveRow(record.key);
            }
          }
        }, [_vm._v("保存")]), _c('a-divider', {
          attrs: {
            "type": "vertical"
          }
        }), _c('a-popconfirm', {
          attrs: {
            "title": "是否要删除此行?"
          },
          on: {
            "confirm": function confirm($event) {
              return _vm.remove(record);
            }
          }
        }, [_c('a', [_vm._v("删除")])])], 1) : _c('span', [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.saveRow(record.key);
            }
          }
        }, [_vm._v("保存")]), _c('a-divider', {
          attrs: {
            "type": "vertical"
          }
        }), _c('a', {
          on: {
            "click": function click($event) {
              return _vm.cancle(record.key);
            }
          }
        }, [_vm._v("取消")])], 1)] : _c('span', [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.editRow(record.key);
            }
          }
        }, [_vm._v("编辑")]), _c('a-divider', {
          attrs: {
            "type": "vertical"
          }
        }), _c('a-popconfirm', {
          attrs: {
            "title": "是否要删除此行?"
          },
          on: {
            "confirm": function confirm($event) {
              return _vm.remove(record);
            }
          }
        }, [_c('a', [_vm._v("删除")])])], 1)];
      }
    }], null, true)
  }), _vm.dataSource.length < 3 ? _c('a-button', {
    staticClass: "addbtn",
    staticStyle: {
      "width": "100%",
      "margin-top": "16px",
      "margin-bottom": "8px"
    },
    attrs: {
      "type": "dashed",
      "icon": "plus"
    },
    on: {
      "click": _vm.newMember
    }
  }, [_vm._v("添加新联系人")]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };