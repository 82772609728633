import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.reduce.js";
export default {
  name: 'StandardTable',
  props: {
    bordered: Boolean,
    loading: [Boolean, Object],
    columns: Array,
    dataSource: Array,
    rowKey: {
      type: [String, Function],
      default: 'key'
    },
    pagination: {
      type: [Object, Boolean],
      default: true
    },
    selectedRows: Array,
    expandedRowKeys: Array,
    expandedRowRender: Function
  },
  data: function data() {
    return {
      needTotalList: []
    };
  },
  methods: {
    updateSelect: function updateSelect(selectedRowKeys, selectedRows) {
      this.$emit('update:selectedRows', selectedRows);
      this.$emit('selectedRowChange', selectedRowKeys, selectedRows);
    },
    initTotalList: function initTotalList(columns) {
      var totalList = columns.filter(function (item) {
        return item.needTotal;
      }).map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          total: 0
        });
      });
      return totalList;
    },
    onClear: function onClear() {
      this.updateSelect([], []);
      this.$emit('clear');
    },
    onChange: function onChange(pagination, filters, sorter, _ref) {
      var currentDataSource = _ref.currentDataSource;
      this.$emit('change', pagination, filters, sorter, {
        currentDataSource: currentDataSource
      });
    },
    customRow: function customRow(record) {
      var _this = this;

      return {
        on: {
          // 事件
          click: function click() {
            _this.$emit('rowClick', record);
          },
          dblclick: function dblclick() {
            _this.$emit('rowdblClick', record);
          }
        }
      };
    }
  },
  created: function created() {
    this.needTotalList = this.initTotalList(this.columns);
  },
  watch: {
    selectedRows: function selectedRows(_selectedRows) {
      this.needTotalList = this.needTotalList.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          total: _selectedRows.reduce(function (sum, val) {
            var v;

            try {
              v = val[item.dataIndex] ? val[item.dataIndex] : eval("val.".concat(item.dataIndex));
            } catch (_) {
              v = val[item.dataIndex];
            }

            v = !isNaN(parseFloat(v)) ? parseFloat(v) : 0;
            return sum + v;
          }, 0)
        });
      });
    }
  },
  computed: {
    selectedRowKeys: function selectedRowKeys() {
      var _this2 = this;

      return this.selectedRows.map(function (record) {
        return typeof _this2.rowKey === 'function' ? _this2.rowKey(record) : record[_this2.rowKey];
      });
    }
  }
};