module.exports = {
  messages: {
    CN: {
      title: '新增用户',
      etitle: '编辑用户',
      desc: '添加用户。',
      input: '请输入',
      disabled: '无法修改',
      mustbenumber: '必须为数字',
      info: '用户信息',
      id: '用户ID',
      name: '单位名称',
      place: '单位地址',
      local: '详细地址',
      contact: '联系人',
      phone: '电话',
      scale: '规模（kg/天）',
      otherinfo: '其他信息',
      username: '登录名',
      password: '密码',
      comment: '备注',
      submit: '提交',
      back: '返回'
    }
  }
};