<template>
  <div class="user_page">
    <add-form
      ref="newForm"
      :visible="showForm"
      :editMode="editMode"
      @cancel="hideForm"
      @create="addNewForm"
    />
    <view-modal
      ref="viewModal"
      :userdata="vmdata"
      :visible="showVModal"
      :spinning="showVModalSpinning"
      @cancel="hideVModal"
    />
    <div class="table_ctrl">
      <a-space class="operator">
        <b style="font-size: 24px;">用户管理</b>
        <span>（ 共 {{ total }} 条数据 ）</span>
        <a-button @click="addNew" type="primary"
          ><a-icon type="plus-circle" theme="filled" />新增用户</a-button
        >
      </a-space>
      <a-space class="operator2">
        <!-- <a-button type="primary" icon="reload" @click="update" /> -->
        <a-input-search
          class="operator2_search"
          v-model="keyword"
          placeholder="输入设备ID、设备型号、单位、联系人、电话"
          allow-clear
          @search="onSearch"
        >
          <a-button type="primary" icon="search" slot="enterButton">
            搜索
          </a-button>
        </a-input-search>
      </a-space>
    </div>
    <standard-table
      class="user_page_table"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="{
        total: total,
        pageSize: pagination.pageSize,
      }"
      @clear="onClear"
      @change="onChange"
      @selectedRowChange="onSelectChange"
    >
      <div slot="description" slot-scope="{ text }">
        {{ text }}
      </div>
      <div slot="action" slot-scope="{ record }">
        <a @click="viewUser(record.key)" style="margin-right: 8px">
          <a-icon type="search" /> 查看
        </a>
        <a-popover
          v-model="rspvisible[record.key]"
          style="margin-right: 8px"
          title="可输入新密码, 或留空重置密码"
          trigger="click"
        >
          <div slot="content">
            <a-input
              v-model="newpsw"
              aria-autocomplete="false"
              placeholder="留空则复位为初始密码"
              style="margin-bottom:20px;"
              allow-clear
            />
            <div class="align_right">
              <a-button
                size="small"
                type="primary"
                @click="resetPsw(record.key)"
              >
                确定
              </a-button>
            </div>
          </div>
          <a> <a-icon type="lock" /> 重置密码 </a>
        </a-popover>
        <a @click="editUser(record)" style="margin-right: 8px">
          <a-icon type="edit" />编辑
        </a>
        <a @click="deleteRecord(record)"> <a-icon type="delete" />删除 </a>

        <!-- <a-popconfirm
          title="Sure to del?"
          @confirm="() => deleteRecord(record)"
        >
          <a> <a-icon type="delete" />删除 </a>
        </a-popconfirm> -->
      </div>
      <template slot="statusTitle">
        <a-icon @click.native="onStatusTitleClick" type="info-circle" />
      </template>
    </standard-table>
    <popup-form
      :visible="visibleConfirm"
      :width="460"
      :maskClosable="false"
      title="确定删除？"
      oktext="确定"
      :destroyOnClose="true"
      @cancel="
        () => {
          this.visibleConfirm = false;
        }
      "
      @ok="
        () => {
          this.visibleConfirm = false;
          this.confirmDelete();
        }
      "
    >
      <div>确定删除用户 <span style="color:#1b79d7;">{{ visibleConfirmSn }}</span> ？</div>
    </popup-form>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import AddForm from "./AddForm";
import ViewModal from "./ViewModal";
const columns = [
  {
    title: "用户ID",
    dataIndex: "customerId",
    width: 120,
    ellipsis: true,
  },
  {
    title: "单位名称",
    dataIndex: "customerName",
    // scopedSlots: { customRender: 'description' }
    ellipsis: true,
  },
  {
    title: "单位地址",
    dataIndex: "customerAddress",
    ellipsis: true,
  },
  {
    title: "联系人",
    dataIndex: "liaison.liaisonName",
    ellipsis: true,
  },
  {
    title: "联系电话",
    dataIndex: "liaison.phone",
    ellipsis: true,
  },
  {
    title: "规模(kg/天)",
    dataIndex: "produceScale",
    ellipsis: true,
  },
  {
    title: "登录名",
    dataIndex: "username",
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remarks",
    ellipsis: true,
  },
  {
    title: "操作",
    width: 280,
    scopedSlots: { customRender: "action" },
  },
];

// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
import { logout } from '@/services/user'
import PopupForm from "@/pages/components/PopupForm";
export default {
  name: "UserList",
  components: { StandardTable, AddForm, ViewModal, PopupForm },
  data() {
    return {
      advanced: true,
      showForm: false,
      showVModal: false,
      showVModalSpinning: false,
      columns: columns,
      dataSource: [],
      total: 0,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 13,
      },
      vmdata: undefined,
      rspvisible: {},
      newpsw: "",
      editMode: false,
      editID: null,
      keyword: "",
      visibleConfirm: false,
      visibleConfirmSn: "",
    };
  },
  // authorize: {
  //   deleteRecord: 'delete'
  // },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      // this.$message.info("update");
      ds.getCustomerList({
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        keyword: this.keyword,
      })
        .then((res) => {
          console.log("getCustomerList", res);
          if (res.data.meta.success) {
            console.log("dataSource", res.data.data.list);
            this.dataSource = res.data.data.list.list.map((item) => {
              return {
                ...item,
                key: item.customerId,
              };
            });
            this.total = res.data.data.list.total;
          } else {
            if(res.data.meta.code === 1006) {
              this.$message.error('获取数据出错：登录已过期，请重新登录');
              logout();
              this.$router.push('/login')
            } else {
              this.$message.error('获取数据出错：' + res.data.meta.msg);
            }
            // this.$message.error(res.data.meta.msg);
          }
        })
        .catch((err) => {
          console.log("getCustomerListErr", err);
          this.$message.error(err.message);
        });
    },
    onSearch(e) {
      // this.$message.info("onSearch");
      console.log("onSearch", e);
      this.update();
    },
    resetPsw(key) {
      console.log("resetPsw", this.newpsw, this.rspvisible);
      ds.resetPassword({
        customerId: key,
        password: this.newpsw ? this.newpsw : "123456",
      })
        .then((res) => {
          console.log("resetPassword: ", res);
          if (res.data.meta.success) {
            this.$message.success("重置密码成功");
            this.rspvisible[key] = false;
          } else {
            this.$message.error(res.data.meta.msg);
          }
        })
        .catch((err) => {
          console.log("resetPasswordErr: ", err);
        });
    },
    confirmDelete() {
      // this.$message.success("还没有接口");

      ds.deleteCustomer(this.visibleConfirmId).then(res=>{
        console.log('deleteCustomer: ', res);
        if(res.data.meta.success) {
          this.$message.success("删除成功")
          this.update();
        } else {
          this.$message.error(res.data.meta.msg)
        }
      }).catch(err=>{
        console.log('deleteCustomerErr: ', err);
      })
      // this.dataSource = this.dataSource.filter(item => item.key !== this.visibleConfirmId)
      // this.selectedRows = this.selectedRows.filter(item => item.key !== this.visibleConfirmId)
    },
    deleteRecord(record) {
      console.log("deleteRecord", record);
      this.visibleConfirm = true;
      this.visibleConfirmSn = record.customerName;
      this.visibleConfirmId = record.customerId;
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      this.$message.info("您清空了勾选的所有行");
    },
    onStatusTitleClick() {
      this.$message.info("你点击了状态栏表头");
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onChange", e);
      const { current } = e;
      this.pagination.current = current;
      this.update();
    },
    onSelectChange() {
      this.$message.info("选中行改变了");
    },
    // addNew () {
    //   this.$router.push('/system/user/add')
    // },
    addNew() {
      this.editMode = false;
      this.showForm = true;
    },
    editUser(record) {
      console.log("editUser", record);
      ds.getCustomerDetail(record.key)
        .then((res) => {
          console.log("getCustomerDetail", res);
          if (res.data.meta.success) {
            this.vmdata = res.data.data.customerDetail;
            this.editMode = true;
            this.showForm = true;
            this.editID = record.customerId;
            this.$nextTick(() => {
              this.$refs.newForm.loadForm(record, this.vmdata);
            });
          } else {
            this.$message.error(res.data.meta.msg);
          }
        })
        .catch(() => {
          this.$message.error("获取数据出错");
        });
    },
    hideForm() {
      let form = this.$refs.newForm.form;
      form.resetFields();
      this.showForm = false;
    },
    addNewForm() {
      let form = this.$refs.newForm.form;
      console.log("newform");
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        console.log("1: ", values);
        if (
          !Array.isArray(values.useradd.place) ||
          values.useradd.place.length < 3
        ) {
          this.$message.warn("请填写完整的单位地址（省/市/县）");
          return;
        } else {
          values.useradd.province = values.useradd.place[0];
          values.useradd.city = values.useradd.place[1];
          values.useradd.town = values.useradd.place[2];
        }
        console.log("2: ", values);
        if (this.$refs.newForm.validateLiaisons()) {
          return;
        }
        this.$refs.newForm.appendLiaisons(values);
        console.log("3: ", values);
        // form.resetFields();
        if (this.editMode) {
          values.useradd.customerId = this.editID;
          ds.updateCustomer(values.useradd)
            .then((res) => {
              console.log("updateCustomer", res);
              if (res.data.meta.success) {
                this.$message.info(res.data.meta.msg);
                this.showForm = false;
                this.update();
              } else {
                this.$message.error(res.data.meta.msg);
              }
            })
            .catch((err) => {
              console.log("updateCustomerErr", err);
            });
        } else {
          ds.addCustomer(values.useradd)
            .then((res) => {
              console.log("addCustomer", res);
              if (res.data.meta.success) {
                this.$message.info(res.data.meta.msg);
                this.showForm = false;
                this.update();
              } else {
                this.$message.error(res.data.meta.msg);
              }
            })
            .catch((err) => {
              console.log("addCustomerErr", err);
            });
        }
      });
    },
    viewUser(key) {
      this.showVModal = true;
      this.vmdata = null;
      this.showVModalSpinning = true;
      ds.getCustomerDetail(key).then((res) => {
        console.log("getCustomerDetail", res);
        if (res.data.meta.success) {
          this.vmdata = res.data.data.customerDetail;
          this.$nextTick(() => {
          })
        } else {
          this.$message.error(res.data.meta.msg);
        }
      }).catch(err => {
        this.$message.error('获取数据发生错误');
        console.error(err)
      }).finally(()=>{
        this.showVModalSpinning = false;
      })
    },
    hideVModal() {
      // this.$router.push('/system/user/view')
      let form = this.$refs.viewModal.form;
      form.resetFields();
      this.showVModal = false;
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user_page {
  padding: 6px;
  margin-top: 7px;
  // margin-bottom: 20px;
  background: rgba(0, 20, 74, 0.8);
  border: 18px solid #027cc3;
  border-image: url(../../../assets/img/card-b.png) 18 round;
  box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  color: #00effc;
  :deep(.ant-table) {
    color: #fff;
  }
  :deep(.ant-table-thead) {
    > tr {
      > th {
        background-color: #02215e;
        color: #00f9ff;
        border-bottom: 0px;
      }
    }
  }
  :deep(.ant-table-tbody) {
    > tr {
      > td {
        border-bottom: 1px solid #fff2;
      }
      &:hover {
        > td {
          background-color: #fff2;
        }
      }
    }
  }
  :deep(.ant-empty-normal) {
    // min-height: 190px;
    background-color: #fff0;
    color: #00f8fe;
  }
  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    color: #00f9ff;
  }

  :deep(.ant-table-pagination) {
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item a {
      color: #2226;
    }
    .ant-pagination-item a:hover {
      color: #2229;
    }
    .ant-pagination-item-active a {
      color: #000e;
    }
  }

  .table_ctrl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .operator2_search {
      width: 300px;
      :deep(.ant-input) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-input-clear-icon) {
        color: #fff6;
        &:hover {
          color: #fff;
        }
      }
    }
    .operator {
      display: flex;
      align-items: center;
    }
  }
  .align_right {
    display: flex;
    flex-direction: row-reverse;
  }

  .search {
    margin-bottom: 54px;
  }
  .fold {
    width: calc(100% - 216px);
    display: inline-block;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  // .user_page_table {
  //   :deep(.ant-table {
  //     min-height: 740px;
  //   }
  // }
  // :deep(.ant-empty-normal) {
  //   min-height: 740px;
  // }
}
</style>
