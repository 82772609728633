var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('popup-form', {
    staticClass: "class_newuser",
    attrs: {
      "visible": _vm.visible,
      "width": 1600,
      "maskClosable": false,
      "title": _vm.editMode ? _vm.$t('etitle') : _vm.$t('title'),
      "destroyOnClose": true
    },
    on: {
      "cancel": function cancel() {
        _vm.$emit('cancel');
      },
      "ok": function ok() {
        _vm.$emit('create');
      }
    }
  }, [_c('a-form', {
    staticClass: "class_newuser_form",
    attrs: {
      "layout": "vertical",
      "form": _vm.form
    }
  }, [_c('div', {
    staticClass: "card mb-10",
    attrs: {
      "title": _vm.$t('info'),
      "bordered": false
    }
  }, [_c('a-divider', {
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('info')) + " ")]), _c('a-row', {
    staticClass: "form-row",
    attrs: {
      "type": "flex",
      "justify": "space-around"
    }
  }, [_c('a-col', {
    attrs: {
      "sm": {
        span: 5
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$t('name')
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.customerName', {
        rules: [{
          required: true,
          message: _vm.$ta('input|name'),
          whitespace: true
        }]
      }],
      expression: "[\n                'useradd.customerName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $ta('input|name'),\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"
    }],
    attrs: {
      "placeholder": _vm.$ta('input|name')
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "sm": {
        span: 5
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$ta('place')
    }
  }, [_c('region-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.place', {
        rules: [{
          type: 'array',
          required: true,
          message: _vm.$ta('input|place')
        }]
      }],
      expression: "[\n                'useradd.place',\n                {\n                  rules: [\n                    { type: 'array', required: true, message: $ta('input|place') },\n                  ],\n                },\n              ]"
    }],
    attrs: {
      "placeholder": _vm.$ta('input|place')
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "sm": {
        span: 5
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$t('local')
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.customerAddress', {
        rules: [{
          required: true,
          message: _vm.$ta('input|local'),
          whitespace: true
        }]
      }],
      expression: "[\n                'useradd.customerAddress',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $ta('input|local'),\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"
    }],
    attrs: {
      "placeholder": _vm.$ta('input|local')
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "sm": 5
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$t('scale')
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.produceScale', {
        rules: [{
          required: true,
          message: _vm.$ta('input|scale'),
          whitespace: true
        }, {
          pattern: /^[0-9]*$/,
          message: _vm.$ta('scale|mustbenumber'),
          whitespace: true
        }]
      }],
      expression: "[\n                'useradd.produceScale',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $ta('input|scale'),\n                      whitespace: true,\n                    },\n                    {\n                      pattern: /^[0-9]*$/,\n                      message: $ta('scale|mustbenumber'),\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"
    }],
    attrs: {
      "placeholder": _vm.$ta('input|scale')
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "form-row",
    attrs: {
      "type": "flex",
      "justify": "space-around"
    }
  }, [_c('a-col', {
    attrs: {
      "sm": 23
    }
  }, [_c('user-form', {
    ref: "refuf",
    on: {
      "change": _vm.liaisonsChange
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "card",
    attrs: {
      "title": _vm.$t('otherinfo'),
      "bordered": false
    }
  }, [_c('a-divider', {
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('otherinfo')) + " ")]), _c('a-row', {
    staticClass: "form-row",
    attrs: {
      "type": "flex",
      "justify": "space-around"
    }
  }, [_c('a-col', {
    attrs: {
      "sm": {
        span: 5
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$t('username')
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.username', {
        rules: [{
          required: true,
          message: _vm.$ta('input|username'),
          whitespace: true
        }]
      }],
      expression: "[\n                'useradd.username',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $ta('input|username'),\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"
    }],
    attrs: {
      "placeholder": _vm.$ta('input|username')
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "sm": {
        span: 5
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$t('password')
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.password', {
        rules: [{
          required: !_vm.editMode,
          message: _vm.$ta('input|password'),
          whitespace: true
        }, {
          min: 6,
          max: 18,
          message: '密码长度应为6~18个字符'
        }, {
          pattern: /^[a-z0-9A-Z]+$/,
          message: '密码只能是数字和字母'
        }]
      }],
      expression: "[\n                'useradd.password',\n                {\n                  rules: [\n                    {\n                      required: !editMode,\n                      message: $ta('input|password'),\n                      whitespace: true,\n                    },\n                    { min: 6, max: 18, message: '密码长度应为6~18个字符' },\n                    { pattern: /^[a-z0-9A-Z]+$/, message: '密码只能是数字和字母' },\n                  ],\n                },\n              ]"
    }],
    attrs: {
      "placeholder": _vm.editMode ? _vm.$ta('disabled|password') : _vm.$ta('input|password'),
      "disabled": _vm.editMode,
      "maxLength": 18
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "sm": {
        span: 10
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": _vm.$t('comment')
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['useradd.remarks'],
      expression: "['useradd.remarks']"
    }],
    attrs: {
      "type": "textarea",
      "auto-size": {
        minRows: 2,
        maxRows: 3
      }
    }
  })], 1)], 1)], 1)], 1)])], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };