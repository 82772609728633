var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-cascader', {
    attrs: {
      "value": _vm.value,
      "options": _vm.options,
      "load-data": _vm.loadData,
      "placeholder": _vm.placeholder,
      "change-on-select": ""
    },
    on: {
      "change": _vm.onChange
    }
  });
};

var staticRenderFns = [];
export { render, staticRenderFns };