import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "standard-table"
  }, [_c('div', {
    staticClass: "alert"
  }, [_vm.selectedRows ? _c('a-alert', {
    attrs: {
      "type": "info",
      "show-icon": true
    }
  }, [_c('div', {
    staticClass: "message",
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._v(" 已选择 "), _c('a', [_vm._v(_vm._s(_vm.selectedRows.length))]), _vm._v(" 项 "), _c('a', {
    staticClass: "clear",
    on: {
      "click": _vm.onClear
    }
  }, [_vm._v("清空")]), _vm._l(_vm.needTotalList, function (item, index) {
    return [item.needTotal ? _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(item.title) + "总计  "), _c('a', [_vm._v(_vm._s(item.customRender ? item.customRender(item.total) : item.total))])]) : _vm._e()];
  })], 2)]) : _vm._e()], 1), _c('a-table', {
    attrs: {
      "bordered": _vm.bordered,
      "loading": _vm.loading,
      "columns": _vm.columns,
      "dataSource": _vm.dataSource,
      "rowKey": _vm.rowKey,
      "pagination": _vm.pagination,
      "expandedRowKeys": _vm.expandedRowKeys,
      "expandedRowRender": _vm.expandedRowRender,
      "rowSelection": _vm.selectedRows ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.updateSelect
      } : undefined,
      "customRow": _vm.customRow
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([_vm._l(Object.keys(_vm.$scopedSlots).filter(function (key) {
      return key !== 'expandedRowRender';
    }), function (slot) {
      return {
        key: slot,
        fn: function fn(text, record, index) {
          return [_vm._t(slot, null, null, {
            text: text,
            record: record,
            index: index
          })];
        }
      };
    }), {
      key: _vm.$scopedSlots.expandedRowRender ? 'expandedRowRender' : '',
      fn: function fn(record, index, indent, expanded) {
        return [_vm._t(_vm.$scopedSlots.expandedRowRender ? 'expandedRowRender' : '', null, null, {
          record: record,
          index: index,
          indent: indent,
          expanded: expanded
        })];
      }
    }], null, true)
  }, [_vm._l(Object.keys(_vm.$slots), function (slot) {
    return _c('template', {
      slot: slot
    }, [_vm._t(slot)], 2);
  })], 2)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };