var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('popup-form', {
    staticClass: "class_newuser",
    attrs: {
      "visible": _vm.visible,
      "width": 460,
      "maskClosable": false,
      "title": "用户信息",
      "destroyOnClose": true,
      "spinning": _vm.spinning,
      "footer": null
    },
    on: {
      "cancel": function cancel() {
        _vm.$emit('cancel');
      }
    }
  }, [_c('div', {
    staticClass: "userinfo"
  }, [_c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("用户ID：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(" " + _vm._s(_vm.userdata ? _vm.userdata.customerId : null) + " ")])]), _c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("单位名称：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(" " + _vm._s(_vm.userdata ? _vm.userdata.customerName : null) + " ")])]), _c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("单位地址：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(" " + _vm._s(_vm.userdata ? _vm.localString : null) + " ")])]), _c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("详细地址：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(" " + _vm._s(_vm.userdata ? _vm.userdata.customerAddress : null) + " ")])]), _vm.userdata ? _c('div', _vm._l(_vm.userdata.liaisonList, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "userinfo_item"
    }, [_c('div', {
      staticClass: "class_key"
    }, [_vm._v("联系人：")]), _c('div', {
      staticClass: "class_value"
    }, [_vm._v(_vm._s(item ? item.liaisonName : null))])]), _c('div', {
      staticClass: "userinfo_item"
    }, [_c('div', {
      staticClass: "class_key"
    }, [_vm._v("联系电话：")]), _c('div', {
      staticClass: "class_value"
    }, [_vm._v(_vm._s(item ? item.phone : null))])])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("规模(kg/天)：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(" " + _vm._s(_vm.userdata ? _vm.userdata.produceScale : null) + " ")])]), _c('a-divider'), _c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("登录名：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(_vm._s(_vm.userdata ? _vm.userdata.username : null))])]), _c('div', {
    staticClass: "userinfo_item"
  }, [_c('div', {
    staticClass: "class_key"
  }, [_vm._v("备注：")]), _c('div', {
    staticClass: "class_value"
  }, [_vm._v(_vm._s(_vm.userdata ? _vm.userdata.remarks : null))])])], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };