var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user_page"},[_c('add-form',{ref:"newForm",attrs:{"visible":_vm.showForm,"editMode":_vm.editMode},on:{"cancel":_vm.hideForm,"create":_vm.addNewForm}}),_c('view-modal',{ref:"viewModal",attrs:{"userdata":_vm.vmdata,"visible":_vm.showVModal,"spinning":_vm.showVModalSpinning},on:{"cancel":_vm.hideVModal}}),_c('div',{staticClass:"table_ctrl"},[_c('a-space',{staticClass:"operator"},[_c('b',{staticStyle:{"font-size":"24px"}},[_vm._v("用户管理")]),_c('span',[_vm._v("（ 共 "+_vm._s(_vm.total)+" 条数据 ）")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_c('a-icon',{attrs:{"type":"plus-circle","theme":"filled"}}),_vm._v("新增用户")],1)],1),_c('a-space',{staticClass:"operator2"},[_c('a-input-search',{staticClass:"operator2_search",attrs:{"placeholder":"输入设备ID、设备型号、单位、联系人、电话","allow-clear":""},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('a-button',{attrs:{"slot":"enterButton","type":"primary","icon":"search"},slot:"enterButton"},[_vm._v(" 搜索 ")])],1)],1)],1),_c('standard-table',{staticClass:"user_page_table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":{
      total: _vm.total,
      pageSize: _vm.pagination.pageSize,
    }},on:{"clear":_vm.onClear,"change":_vm.onChange,"selectedRowChange":_vm.onSelectChange},scopedSlots:_vm._u([{key:"description",fn:function({ text }){return _c('div',{},[_vm._v(" "+_vm._s(text)+" ")])}},{key:"action",fn:function({ record }){return _c('div',{},[_c('a',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.viewUser(record.key)}}},[_c('a-icon',{attrs:{"type":"search"}}),_vm._v(" 查看 ")],1),_c('a-popover',{staticStyle:{"margin-right":"8px"},attrs:{"title":"可输入新密码, 或留空重置密码","trigger":"click"},model:{value:(_vm.rspvisible[record.key]),callback:function ($$v) {_vm.$set(_vm.rspvisible, record.key, $$v)},expression:"rspvisible[record.key]"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('a-input',{staticStyle:{"margin-bottom":"20px"},attrs:{"aria-autocomplete":"false","placeholder":"留空则复位为初始密码","allow-clear":""},model:{value:(_vm.newpsw),callback:function ($$v) {_vm.newpsw=$$v},expression:"newpsw"}}),_c('div',{staticClass:"align_right"},[_c('a-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.resetPsw(record.key)}}},[_vm._v(" 确定 ")])],1)],1),_c('a',[_c('a-icon',{attrs:{"type":"lock"}}),_vm._v(" 重置密码 ")],1)]),_c('a',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.editUser(record)}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v("编辑 ")],1),_c('a',{on:{"click":function($event){return _vm.deleteRecord(record)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v("删除 ")],1)],1)}}])},[_c('template',{slot:"statusTitle"},[_c('a-icon',{attrs:{"type":"info-circle"},nativeOn:{"click":function($event){return _vm.onStatusTitleClick.apply(null, arguments)}}})],1)],2),_c('popup-form',{attrs:{"visible":_vm.visibleConfirm,"width":460,"maskClosable":false,"title":"确定删除？","oktext":"确定","destroyOnClose":true},on:{"cancel":() => {
        this.visibleConfirm = false;
      },"ok":() => {
        this.visibleConfirm = false;
        this.confirmDelete();
      }}},[_c('div',[_vm._v("确定删除用户 "),_c('span',{staticStyle:{"color":"#1b79d7"}},[_vm._v(_vm._s(_vm.visibleConfirmSn))]),_vm._v(" ？")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }